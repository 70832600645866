<template>
  <div class="home" id="quotaReviewList">
    <div style="text-align: left" >
      <el-form :inline="true" :model="queryForm" class="demo-form-inline">
        <el-form-item label="月度汇总表编号：">
          <el-input v-model="queryForm.summaryNo" placeholder="请输入查询" :clearable="true"></el-input>
        </el-form-item>
        <!-- <el-form-item label="状态：">
          <el-select v-model="queryForm.state" placeholder="请选择">
            <el-option label="全部状态" value="-1"></el-option>
            <el-option v-for="(itme,i) in itemList" :key="i" :label="itme.name" :value="itme.datavalue"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="loadTable" icon="el-icon-search">查询</el-button>
          <el-button type="primary" @click="onReset" icon="el-icon-refresh-right">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
        <el-table  :header-cell-style="tableHeader" :cell-style="tableCell" :data="tableData" fit border style="width: 100%">
        <el-table-column type="index" width="50" />
        <el-table-column prop="summaryNo" :show-overflow-tooltip="true"  label="汇总表编号" />
        <el-table-column prop="status" :show-overflow-tooltip="true"  label="状态">
          <template slot-scope="scope">
            <div v-for="(item, index) in itemList" v-bind:key="index" >
              <div v-if="item.datavalue == scope.row.status">
                {{  item.name }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="totalPayrollPayable" :show-overflow-tooltip="true" label="总应发工资" ><template slot-scope="scope">{{accDiv(scope.row.totalPayrollPayable,100)}}</template></el-table-column>
        <el-table-column prop="totalPersonaltax" :show-overflow-tooltip="true" label="总个税" ><template slot-scope="scope">{{accDiv(scope.row.totalPersonaltax,100)}}</template></el-table-column>
        <el-table-column prop="totalTaxDifference" :show-overflow-tooltip="true" label="个税差异金额" ><template slot-scope="scope">{{accDiv(scope.row.totalTaxDifference,100)}}</template></el-table-column>
        <el-table-column prop="totalFinalTax" :show-overflow-tooltip="true" label="总最终个税" ><template slot-scope="scope">{{accDiv(scope.row.totalFinalTax,100)}}</template></el-table-column>
        <el-table-column prop="totalNetSalary" :show-overflow-tooltip="true" label="总实发工资" ><template slot-scope="scope">{{accDiv(scope.row.totalNetSalary,100)}}</template></el-table-column>
        <!-- <el-table-column prop="totalFixedServicecharge" :show-overflow-tooltip="true" label="总固定服务费" ><template slot-scope="scope">{{accDiv(scope.row.totalFixedServicecharge,100)}}</template></el-table-column> -->
        <el-table-column prop="totalIncrementTaxation" :show-overflow-tooltip="true" label="总增值税费" ><template slot-scope="scope">{{accDiv(scope.row.totalIncrementTaxation,100)}}</template></el-table-column>
        <el-table-column prop="totalLocaltaxSurchargeTaxation" :show-overflow-tooltip="true" label="总地税附加税费"><template slot-scope="scope">{{accDiv(scope.row.totalLocaltaxSurchargeTaxation,100)}}</template></el-table-column>
        <el-table-column prop="totalFixedServicecharge" :show-overflow-tooltip="true" label="总固定服务费"><template slot-scope="scope">{{accDiv(scope.row.totalFixedServicecharge,100)}}</template></el-table-column>
        <el-table-column prop="totalStaffFixedServicecharge" :show-overflow-tooltip="true" label="总人员固定服务费"><template slot-scope="scope">{{accDiv(scope.row.totalStaffFixedServicecharge,100)}}</template></el-table-column>
        <el-table-column prop="totalServicecharge" :show-overflow-tooltip="true" label="总服务费"><template slot-scope="scope">{{accDiv(scope.row.totalServicecharge,100)}}</template></el-table-column>
        <el-table-column prop="totalActualDeductibleExpenses" :show-overflow-tooltip="true" label="本月总实际抵扣费用" ><template slot-scope="scope">{{sum(accDiv(scope.row.totalActualDeductibleExpenses,100),2)}}</template></el-table-column>
        <el-table-column prop="totalCorpInvoiceAmountExpenses" :show-overflow-tooltip="true" label="总开票金额"></el-table-column>
        <el-table-column prop="totalCorpInvoiceAmountExpenses" :show-overflow-tooltip="true" label="支付给我司费用总计"></el-table-column>
        <el-table-column prop="totalDeductibleExpenses" :show-overflow-tooltip="true" label="次月可扣除总费用"><template slot-scope="scope">{{accDiv(scope.row.totalDeductibleExpenses,100)}}</template></el-table-column>
        <!-- <el-table-column prop="totalTaxDifference" :show-overflow-tooltip="true" label="个税差异金额"><template slot-scope="scope">{{accDiv(scope.row.totalTaxDifference,100)}}</template></el-table-column> -->
        <el-table-column prop="examinepeople" :show-overflow-tooltip="true" label="审核人" />
        <el-table-column prop="examineTime" :show-overflow-tooltip="true" label="审核时间" />
        <el-table-column prop="updateTime" :show-overflow-tooltip="true" label="上传时间" />
        <!-- <el-table-column prop="submitUser" :show-overflow-tooltip="true" label="提交人员" /> -->
        <el-table-column label="操作" width="150px">
          <template slot-scope="scope">
            <el-button @click="noDetails(scope.row)" type="text" size="small">详情</el-button> 
            <el-button type="text" size="small" v-if="scope.row.status!=2" @click="quotaReviewdiaLog(scope.row,0)" style="color: #09BF88">薪资审核</el-button>
            <el-button type="text" size="small"  v-permissions="'发薪'" @click="pay(scope.row)" >发薪</el-button>
          </template>
        </el-table-column>
      </el-table>
     <el-pagination
        id="page"
        background
        @size-change="sizeChange"
        @current-change="currentChange"
        @prev-click="currentChange"
        @next-click="currentChange"
        :page-sizes="[5, 10, 20]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <div>

      <el-dialog title="额度审核" class="quotaReviewDialag" id="clear_layout" :visible.sync="dialogVisibledetails" @close="$refs['dialogform'].resetFields();">
        <el-form id="dialogform" :model="dialogform" :rules="rules" ref="dialogform" > 
          <el-form-item label="额度审核:" prop="radio" >
            <el-radio-group v-model="dialogform.radio">
              <el-radio  label="2">申请通过</el-radio>
              <el-radio  label="4">退回申请</el-radio>
          </el-radio-group>
              
          </el-form-item>
          <el-form-item label="备注:" prop="desc">
              <el-input v-model="dialogform.remark" type="textarea"  ></el-input>
        </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibledetails = false;"  >取 消</el-button>
          <el-button type="primary" @click="submitForm('dialogform')">确 定</el-button>
        </div>
      </el-dialog>
      
      <!-- <el-dialog
        :visible.sync="auditMsgs.dialogVisible"
        width="30%"
        >
        <span :class="auditMsgs.msgClass">{{auditMsgs.msg}}</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="auditMsgs.dialogVisible = false">取 消</el-button>
          <el-button :class="auditMsgs.buttonClass" type="primary" @click="salaryapply">确 定</el-button>
        </span>
      </el-dialog> -->

      <el-dialog
        :visible.sync="auditMsgs.dialogVisible"
        width="30%"
        >
        <span :class="auditMsgs.msgClass">{{auditMsgs.msg}}</span>
        <el-form class="wageadjustments" :model="codes" :inline="true" :label-position="'right'"  :rules="rules" ref="updateForm" label-width="150px">
          <el-form-item label="手机号:" >
            <el-input class="inputVa" style="width:160px" v-model="codes.mobile" :disabled="true" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item label="验证码:"  prop="code" >
            <el-input class="inputCode" style="width:160px" v-model="codes.code" placeholder="请输入验证码"></el-input>
            <el-button @click="getCode" type="success" :disabled="!show" >获取验证码
            <span v-show="!show" style="font-size:5px" class="count">({{countDown}} s)</span></el-button>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="auditMsgs.dialogVisible = false">取 消</el-button>
          <el-button :class="auditMsgs.buttonClass" type="primary" @click="submitForms('updateForm')" v-loading.fullscreen.lock="fullscreenLoading" element-loading-text="正在处理中，请不要重复操作！">确 定</el-button>
        </span>
      </el-dialog>
     
    </div>
  </div>
</template>
<script>

import XLSX from "xlsx"

import validateUtils from '@/util/validateUtils'

export default {
    data() {
      return {
        countDown:'',
        show: true,
        fullscreenLoading:false,
        codes:{
          code: "",
          show: true,
          countDown: 60,
          timer: null,
          isShow: false,
          mobile: '',
        },
        rules: {
           radio: [
            { required: true, message: '请选择额度审核', trigger: 'change' }
          ],
        },
        // tableLoading: true,
        upload: process.env.VUE_APP_BASE_URL+'/salarySummarizing/importSalary',
        // 0为汇总
        type: 0,
        queryForm: {
          id:'',
          uid: '',
          summaryNo:'',
          groupIds: null,
          groupId: '',
          state: '',
          pageNo: 1,
          pageSize: 10
        },
        userData:'',
        total:0,
        // 这个站点对象集合
        groupList: [],
        options: [],
        // 状态字典列表
        summaryStateList:[],
        // 以表示排列的数组
        summaryArray: null,
        // 表格对象
        tableData: [],
        // input: '',
        updateForm: {
          groupIds: [],
          groupId: this.queryFormGetGroupIds,
          groupName: '',
          sessionId: '',
          type: 0,
          creditLimitApprovalId: '',
          isFile: null,
        },

        auditMsgs: {
          dialogVisible: false,
          msg: '确认撤销审核该汇总表吗？',
          msgClass: 'submitMsg',
          buttonClass: 'submitButton',
        },
        
        uploadJson:{
          json: {},
          userId: '111111',
          type: process.env.VUE_APP_UPLOAD_TYPE,
          queue: process.env.VUE_APP_UPLOAD_SALARY_SUMMARY_QUENCE_NAME,
        },
        formInline: {
          user: '',
          region: ''
        },
        dialogFormVisible: false,
        innerVisible: false,
        fileList: [],
        
        // 这个是解析表格
         changeStateParams:{
        id: '',
         mobile:'',
          code:''
      },
        
        parsePage:{
          pageNo: 1,
          pageSize: 10,
        },
        
        queryParams: {
        pageNo: 1,
        pageSize: 10,
        code: "",
        name: "",
      },
      itemList: [],
      diction: {
					code: "summaryStatus",
				},
      changeStateParams:{
        id: '',
        state: null,
        sessionId: ''
      },
      dialogVisibledetails: false,
      dialogform: {
          radio: null,
          remark: null,
      },
      rules: {
          
          code: [
            { required: true, message: '验证码不能为空！', trigger: 'blur' }
          ],
        }
      }
    },
    computed: {
      parseTableData(){
        return this.parseTable.tableData.slice((this.parsePage.pageNo-1)*this.parsePage.pageSize,this.parsePage.pageSize*this.parsePage.pageNo);
      },
      parseTableTotal(){
        return this.parseTable.tableData.length;
      },
      // queryFormGetGroupIds() {
      //   let groupIds = this.queryForm.groupIds;
      //   if(this.isEmpty(groupIds)){
      //     return null;
      //   }else{
      //     return groupIds[groupIds.length-1];
      //   }
      // }
    },
    watch: {
      queryFormGetGroupIds(newVal, oldVal){
        this.queryForm.groupId = newVal;
      }
    },
    // 实例创建完成后调用
    async created(){
	
	  this.loadTable();
    this.loadMobile();
    },

     mounted: function(){
				this.findDictionariesItemList(this.diction);
		},

    methods: {
      // 查询用户手机号
      async loadMobile(){
        var params = {
        
      };
        await this.api.salaryApi.finUserInfo(params).then(res =>{
          if(res.status = 200){
            this.userData = res.data;
            console.log("----------==",this.userData)
          }else{
            this.$message.error('查询手机号失败！');
          }
        })
      },
            //发送验证码
      async getCode(){
        const TIME_COUNT = 60;
				if (!this.timer) {
					this.countDown = TIME_COUNT;
					this.show = false;
					this.timer = setInterval(() => {
						if (this.countDown > 0 && this.countDown <= TIME_COUNT) {
							this.countDown--;
						} else {
							this.show = true;
							clearInterval(this.timer);
							this.timer = null;
						}
					}, 1000)
				}
        await this.api.salaryApi.sendCode(this.codes).then((res) => {
          console.log("-------发送验证码",res);
          res = res.data
          if(res.code == 200){
            this.$message.success(res.msg);
          }else{
            this.$message.error(res.msg);
          }
        })
      },
      submitForms(updateForm){
        console.log("ceshi--------",updateForm);
            this.$refs[updateForm].validate((valid) => {
            if (valid) {
              this.pays();
            } else {
              console.log('error submit!!');
              return false;
            }
          });
        },
      pays(){
          console.log("----------0000",this.changeStateParams)
          this.fullscreenLoading = true;
          this.changeStateParams.code=this.codes.code
          this.api.salaryApi.bb6bthhl(this.changeStateParams).then((res)=>{
            res = res.data
            if(res.code == 200){
              this.$message.success(res.msg)
              this.fullscreenLoading = false;
              this.auditMsgs.dialogVisible = false;
              this.loadTable()
            }else{
              this.$message.error(res.msg)
              this.fullscreenLoading = false;
              // this.auditMsgs.dialogVisible = false;
            }
          })
        },
      quotaReviewdiaLog(row) {
       this.id=row.id;
				this.dialogVisibledetails = true;
			},
      async pay(row){
         this.queryForm.id =  row.id
        await this.api.salaryApi.findSalaryState(this.queryForm).then((res) => {
            console.log("-------查询发薪状态",res);
            res = res.data
            if(res.code == 200){
              this.auditMsgs.dialogVisible = true;
              this.auditMsgs.msg='确认发薪吗？'
              this.auditMsgs.msgClass='submitMsg'
              this.auditMsgs.buttonClass='submitButton'
              this.changeStateParams.id=row.id;
              this.codes.mobile=this.userData.mobile
              this.changeStateParams.mobile=this.userData.mobile;
              this.codes.code='';
            }else{
              this.$message.error(res.msg);
            }
        })
      },
      async quotaReview() {
        if(this.dialogform.radio == 2){
          this. dialogVisibledetails = false;
          var params = {
            id: this.id,
            content: this.dialogform.remark,
            state: this.dialogform.radio,
            sessionId: this.$getCookie("sessionId")
          };
          console.log("---------",params);
          await this.api.salaryApi.updateAuditCompletedStatus(params).then(res =>{
            console.log("----------",res);
            if(res.data.code==200){
              this.$message({
                  message: '操作成功！',
                  type: 'success'
              });
              this.loadTable();
            }else{
              this.$message.error('操作失败！');
              this.loadTable();
            }
          });
        }else if (this.dialogform.radio == 4){
            this. dialogVisibledetails = false;
            var params = {
              id: this.id,
              content: this.dialogform.remark,
              state: this.dialogform.radio,
              sessionId: this.$getCookie("sessionId")
            };
            console.log("---------",params);
            await this.api.salaryApi.updateAuditFailedStatus(params).then(res =>{
              console.log("----------",res);
              if(res.data.code==200){
                this.$message({
                    message: '操作成功！',
                    type: 'success'
                  });
               this.loadTable();
            }else{
              this.$message.error('操作失败！');
              this.loadTable();
            }
            });
        }
     
    },

       // 分页查看薪资信息列表
		async loadTable(){
			this.loading = true;
			let flag = this;
      console.log(this.queryForm);
			//getStaffList
			await this.api.salaryApi.summarizingStatusOneAndTwo(this.queryForm).then((res) => {
				res = res.data
				console.log("----列表-----",res);
				// flag.loading = false;
				if(res.code == 200){
					flag.tableData = res.data.records;
					flag.total = res.data.total
          console.log("----列表ssss-----",flag.tableData);
          this.tableData.forEach(item => {
            item.totalCorpInvoiceAmountExpenses = (item.totalCorpInvoiceAmountExpenses/100).toFixed(2)
          })
          
				}else{
          this.tableData =  null;
          this.total = 0;
          this.$message.error(res.msg);
        }
			})
		},
    submitForm(formName) {
      console.log(formName)
        this.$refs['dialogform'].validate((valid) => {
          if (valid) {
                  console.log("22222222")
            this.quotaReview();
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
    
		warning(msg) {
        this.$message({
          message: msg,
          type: 'warning'
        });
      },

      success(msg) {
        this.$message({
          message: msg,
          type: 'success'
        });
      },


      // openError
      openError(row){
        let proofId = row.proofId;
        let groupId = row.groupId;
        this.$router.push({path:'/summaryErrorList',query: {proofId,groupId}})
      },
      // 查看函数
      noDetails(row){
        this.$router.push({path: '/summaryApprovalDetails',query: {id: row.id,summaryNo:row.summaryNo,updateTime:row.updateTime}})
      },
      // 作废
      cancellation(){
        
      },

      // parseXlsxPageChange
      parseXlsxPageChange(page){
        console.log(page+'page');
        this.parsePage.pageNo = page;
      },
      //parseXlsxPagesizeChange 
      parseXlsxPagesizeChange(size){
        console.log(size+'size');
        this.parsePage.pageSize = size;
      },
      // 分页器 size改变是触发
       //current-change
      currentChange(page){
        this.queryForm.pageNo = page;
        this.loadTable();
      },

      // size-change
      sizeChange(size){
        this.queryForm.pageSize = size;
        this.loadTable();
      },
      // 改变状态
     
      // // 条件查询
      // onQuery() {
      //   let groupIds = this.queryForm.groupIds;
      //   if(groupIds!= undefined && groupIds.length>0){
      //     this.queryForm.groupId = groupIds[groupIds.length-1]
      //   }
      //   this.loadTable();
      // },
      // 加载查看列表函数
      // async loadTable(){
      //   // tableData
      //   // this.tableLoading = true;
      //   let flag = this;
      //   await this.api.platformApi.listImportSalary(this.queryForm).then(res => {
      //     res = res.data
      //     this.tableLoading = false;
      //     let data = res.data;
      //     let list = data.records;
      //     for (let i in list) {
      //       let state = flag.summaryArray[list[i].state];
      //       let name = '';
      //       if(!this.isEmpty(state)){
      //         name = state.name;
      //       }
      //       list[i]['stateName'] = name
      //     }
      //     flag.tableData = list;
      //     flag.total=data.total;
          
      //   })
      // },
      // 上传表单
      
      // afteraddOrUpdateDialogClose 清空弹窗表单
      afteraddOrUpdateDialogClose(){
          this.resetForm('updateForm');
          this.$refs.upload.$data.uploadFiles = [];
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      
      // 重置
      onReset(){
        this.queryForm.summaryNo='';
        this.queryForm.proofId='';
        this.queryForm.groupId='';
        this.queryForm.state='';
      },
      // 上传表格
      submitUpload() {
        console.log('submitUpload!');
        this.$refs.upload.submit();
      },
      // 删除一选择的上传文件函数
      handleRemove(file, fileList) {
        console.log('handleRemove!');
        this.updateForm.isFile = null;
        console.log(file, fileList);
      },
      // 点击文件列表中已上传的文件时的钩子
      handlePreview(file) {
        console.log('handlePreview!');
        console.log(file);
      },
      // 上传失败的钩子
      handleError(err, file, fileList){
        this.$message.error('上传失败！');
      },
      // 上传成功的钩子
      handlesuccess(response, file, fileList){
        this.updateForm.groupId = [];
        this.fileList = []
        this.dialogFormVisible = false;
        this.$message({
          message: '上传成功！',
          type: 'success'
        });
        console.log('handlesuccess');
        this.loadTable();
      },
      // formatDate(numb, format) {
      //   const old = numb - 1;
      //   const t = Math.round((old - Math.floor(old)) * 24 * 60 * 60);
      //   const time = new Date(1900, 0, old, 0, 0, t)
      //   const year = time.getFullYear() ;
      //   const month = time.getMonth() + 1 ;
      //   const date = time.getDate() ;
      //   return year + format + (month < 10 ? '0' + month : month) + format + (date < 10 ? '0' + date : date)
      // },
      // // 验证时间是否合法
      // checkDate(dateStr){
      //   var a = /^((((1[6-9]|[2-9]\d)\d{2})\/(0?[13578]|1[02])\/(0?[1-9]|[12]\d|3[01]))|(((1[6-9]|[2-9]\d)\d{2})\/(0?[13456789]|1[012])\/(0?[1-9]|[12]\d|30))|(((1[6-9]|[2-9]\d)\d{2})\/0?2\/(0?[1-9]|1\d|2[0-8]))|(((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))\/0?2\/29\/))$/;
      //   if (a.test(dateStr)) {
      //       return true;
      //   }else{
      //       return false;
      //   }
      // },
      // // 非空验证
      // isEmpty(strIn) {
      //     if (strIn === undefined) {
      //         return true;
      //     } else if (strIn == null) {
      //         return true;
      //     } else if (strIn == "") {
      //         return true;
      //     } else {
      //         return false;
      //     }
      // },
      async findDictionariesItemList(param) {
				let response = await this.api.platformApi.findDictionariesItemList(param);
				console.log("数据字典", response);
				this.itemList = response.data.data;
			},
      tableHeader(column) {
        return "height:36px;background-color:#fafafa;color:#333333;padding:0";
      },
      tableCell(column) {
        return "height:40px;background-color:#fafafa;color:#000000;padding:0";
      },
    },
}
</script>

<style scoped>
.submitMsg{
      color: red;
      font-size: 1.5rem;
      
    }
.wageadjustments{
  
  margin: 30px 50px;
  text-align: left;
}
  /* .submitMsg{
    color: #09BF88;
    font-size: 1.5rem;
    
  } */
  .submitButton{
    background-color: red;
    border: none;
  }
  .submitButton:hover{
    background-color: #409EFF;
  }
  .repealMsg{
    color: #FFAC1D;
    font-size: 1.5rem;
  }
  .repealButton{
    background-color: #FFAC1D;
    border: none;
  }
  .repealButton:hover{
    background-color: #FFBD4A;
  }

  .cancellationMsg{
    color: #F78969;
    font-size: 1.5rem;
  }
  .cancellationButton{
    background-color: #F78969;
    border: none;
  }
  .cancellationButton:hover{
    background-color: #F789;
  }
/* -------------------------------------- */

#page {
  float: right;
  margin-top: 15px;
}

#quotaReviewList {
  overflow: hidden;
  background-color: white;
  padding: 15px 20px 100px;
}

.el-dialog__body {
  border-top: 1px solid rgba(228, 228, 228, 1);
  border-bottom: 1px solid rgb(228, 228, 228, 1);
  padding-left: 0;
  padding-right: 0;
}

.el-drawer.rtl {
  overflow: scroll;
}

.updateForm{
  width: 90%;
  margin: 0 auto;
}
.parseXlsxBottom{
  text-align: end;
  padding: 20px;
}
</style>


