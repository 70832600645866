<template>
  <div class="echart">
    <el-row :gutter="20">
      <el-col :span="8">
        <div class="top-left">
          <div class="top-left-top">
            <span>{{account.accountName}}</span>
          </div>
          <div class="top-left-middle">
            <div class="balance">
              <div class="seconds-title" >账户余额(元):<span class="amount">{{account.amount/100}}</span></div>
              <el-statistic
                group-separator=","
                :precision="2"
                :value="32131231"
              ></el-statistic>
            </div>
          </div>
          <div class="top-left-bottom">
            <el-button @click="noDetails()" class="btn" type="danger" size="small">发薪</el-button>
            <el-button class="btn1" plain size="small" @click="dialogVisible = true">充值</el-button>
            <el-button @click="noDetailss()" class="btn1" plain size="small">交易明细</el-button>
          </div>
        </div>
      </el-col>
      <el-col :span="16">
        <div class="top-right">
          <div class="top-right-middle">
            <div class=" piechart-right">
             <div class="data_name">
                <span class="second-titles">本月发薪次数：</span>
                <div class="data_amount">{{PayrollNumber.number}}</div>
              </div>
              <div class="data_name">
                <span class="second-titles">本月成功发薪(元)：</span>
                <div class="data_amount">{{PayrollInfo.total_Salary}}</div>
              </div>
              <div class="data_name">
                <span class="second-titles">本月成功发薪人数：</span>
                <div class="data_amount">{{PayrollInfo.total_Salary_Number}}</div>
              </div>
              <div class="data_name">
                  <span class="second-titles">已完成开票申请：</span>
                  <div class="data_amount" style="font-size: 18px;">敬请期待</div>
              </div>
              <div class="data_name">
                  <span class="second-titles">可开票额度(元)：</span>
                  <div class="data_amount" style="font-size: 18px;">敬请期待</div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20" style="margin-top: 16px;">
      <el-col :span="8">
        <div class="main-middle" @click="noDetailcontract()">
          <img src="@/static/images/charticon.png" alt="" />
          <div style="text-align: left;margin-left: 16px;">
            <div class="middle-title">
              合同管理
            </div>
            <div class="second-title">
              查看并下载员工签署的电子合同
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="main-middle" @click="noDetailsalary()">
          <img src="@/static/images/charticon.png" alt="" />
          <div style="text-align: left;margin-left: 16px;">
            <div class="middle-title">
              发薪记录
            </div>
            <div class="second-title">
              查看本次发薪结果和明细
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="main-middle" @click="noDetailinvoice()">
          <img src="@/static/images/charticon.png" alt="" />
          <div style="text-align: left;margin-left: 16px;">
            <div class="middle-title">
              发票管理
            </div>
            <div class="second-title">
              点击提交开票申请
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <div class="main-bottom" style="margin-top: 16px;">
      <div class="main-bottom-top">
        <span>发薪金额(万)</span>
        <div>
          <div class="icon-text-red">发薪</div>
          <!-- <div class="icon-text-yellow">发票</div> -->
        </div>
      </div>
      <div id="barchart"></div>
    </div>
        <div>
      <el-dialog
        title="充值账户信息"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
        <span>
          <el-form   label-width="110px">
              <el-form-item label="户名：" >
                  <el-col :span="18">
                    <el-input v-model="infoForm.hm"></el-input>
                  </el-col>
              </el-form-item>
              <el-form-item label="账户：" >
                  <el-col :span="18">
                    <el-input v-model="infoForm.zh"></el-input>
                  </el-col>
              </el-form-item>  
              <el-form-item label="开户行：" >
                  <el-col :span="18">
                    <el-input v-model="infoForm.khh"></el-input>
                  </el-col>
              </el-form-item>  
            </el-form>
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span>
      </el-dialog>
    </div>

  </div>
</template>
<script>
import echarts from 'echarts'
export default {
  data() {
    return {
      activeName: '',
      piechart: null,
      barchart: null,
      PayrollInfo:'',
      PayrollNumber:'',
      account:{},
      pageHistogram:'',
      dialogVisible: false,
      infoForm:{
        hm:'四川友叁道人力资源服务有限公司',
        zh:'120926295110002',
        khh:'招商银行股份有限公司广州白云新城支行'
      },

    }
  },
  mounted() {
    this.findPageHistogram()
    this.listPayrollInfos();
    this.listPayrollNumber();
    this.subunitBalance();
    // this.setBarchart()
  },
  methods: {
    //首页柱状图
    async findPageHistogram() {
      await this.api.salaryApi.findPageHistogram(this.queryForm).then(res => {
        console.log("-----00000000",res)
          this.pageHistogram = res.data.data;
          console.log("-----1111111",this.pageHistogram)
          this.setBarchart(this.pageHistogram)
        });
    },
    //查询本月发薪人数和金额
    async listPayrollInfos() {
      this.tableLoading = true;
      await this.api.salaryApi.listPayrollInfo(this.queryForm).then(res => {
        console.log("-----",res)
          this.PayrollInfo = res.data.data;
          console.log("-----",this.PayrollInfo)
        });
    },
    //查询账户余额
    async subunitBalance() {
      this.tableLoading = true;
      await this.api.salaryApi.subunitBalance(this.queryForm).then(res => {
        console.log("-----",res)
          this.account = res.data.data.data;
          console.log("-----",this.PayrollInfo)
        });
    },

    //查询本月发薪次数
    async listPayrollNumber() {
      this.tableLoading = true;
      await this.api.salaryApi.listPayrollNumber(this.queryForm).then(res => {
        // console.log("-----",res)
          this.PayrollNumber = res.data.data;
          console.log("-----",this.PayrollNumber)
        });
    },
    setPiechart() {
      this.piechart = echarts.init(document.getElementById('piechart'))
      const option = {
        series: [
          {
            name: '访问来源',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '30',
                fontWeight: 'bold',
              },
              selectedOffset: -10,
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: 310, itemStyle: { color: '#fa4b47' } },
              { value: 600, itemStyle: { color: '#ccc' } },
            ],
          },
        ],
      }
      this.piechart.setOption(option)
      this.piechart.dispatchAction({
        type: 'highlight',
        seriesIndex: 0, // 图表中的第一个系列
        dataIndex: 0, // 要高亮的数据项的索引
      })
    },
    setBarchart(arr) {
      console.log('arr:',arr)
      let xAxisDatas = [],totalAmountDatas = []
      for(let i = 0 ;  i < arr.length; i ++){
         xAxisDatas.push(arr[i].startTime)
         totalAmountDatas.push(arr[i].amount)
      }

      this.barchart = echarts.init(document.getElementById('barchart'))
      const option = {
        grid: {
          top: 20,
          left: 60,
          bottom: 30,
          right: 60,
        },
        tooltip: {},
        xAxis: {
          type: 'category',
          data: xAxisDatas,
        },
        yAxis: {},
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [
            //发薪金额
          {
            type: 'bar',
            itemStyle: { color: '#fa4b47', barBorderRadius: [10, 10, 0, 0] },
            barMaxWidth: 15,
            data: totalAmountDatas,
          },
          //发票金额
          {
            type: 'bar',
            itemStyle: {
              color: '#faac3c',
              barBorderRadius: [10, 10, 0, 0],
            },
            barMaxWidth: 15,
            // data: [43.3, 43.3, 43.3, 43.3, 43.3, 43.3],
          },
        ],
      }
      this.barchart.setOption(option)
      this.barchart.dispatchAction({
        type: 'highlight',
        seriesIndex: 0, // 图表中的第一个系列
        dataIndex: 0, // 要高亮的数据项的索引
      })
    },
    noDetails(){
        this.$router.push({path: '/salaryList'})
    },
    noDetailss(){
        this.$router.push({path: '/tran',})
    },
    noDetailcontract(){
        this.$router.push({path: '/contractList'})
    },
    noDetailinvoice(){
        this.$router.push({path: '/invoiceWritebackList'})
    },
    noDetailsalary(){
        this.$router.push({path: '/payrollLog'})
    },
  },
}
</script>
<style scoped>
>>> .el-statistic .con {
  justify-content: flex-start;
}
.amount{
    margin-left: 30px;
    font-weight: 900;
}
.data_amount{
    margin-top: 20px;
    font-weight: 900;
    font-size: 28px;
}
.btn{
    width: 100px;
}
.btn1{
    width: 100px;
    color: red;
}
.data_name{
    margin-top: 35px;
}
.echart {
  height: calc(100vh - 120px);
  /* background-color: #fff; */
}
.seconds-title{
    font-size: 24px;
}
.top-left,
.top-right {
  background-color: #fff;
  padding: 16px;
  box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.05);
}
.top-left-top,
.top-left-middle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.balance {
  background-color: #fff5f5;
  flex: 1;
  text-align: left;
  margin-top: 16px;
  padding: 16px;
}
.balance + .balance {
  margin-left: 16px;
}
.second-title {
  font-size: 14px;
  color: rgb(149, 149, 149);
}
.second-titles {
  font-size: 14px;
  color: #000;
}
.account {
  font-size: 18px;
  margin-top: 8px;
}
.top-left-bottom {
  margin-top: 16px;
}
.top-right-top {
  display: flex;
  align-items: center;
}

.tabs + .tabs {
  margin-left: 8px;
}
#piechart {
  width: 50%;
  height: 166px;
}
.top-right-middle {
  /* display: flex; */
  align-items: center;
  justify-content: space-between;
}
.piechart-right {
  height: 155px;
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  margin-top: 0;
}
.main-middle {
  background-color: #fff;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.middle-title {
  font-size: 20px;
  margin-bottom: 8px;
}
.main-bottom {
  background-color: #fff;
  height: 368px;
  padding: 16px;
  box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.05);
}
.main-bottom-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.icon-text-red,
.icon-text-yellow {
  display: inline-block;
}
.icon-text-red::before,
.icon-text-yellow::before {
  content: '';
  display: inline-block;
  background-color: #000;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin: 0 8px;
  vertical-align: middle;
}
.icon-text-red::before {
  background-color: #fa4b47;
}
.icon-text-yellow::before {
  background-color: #faac3c;
}
#barchart {
  height: 350px;
}
</style>
